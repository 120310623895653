.btn-group > .btn {
    margin-top: -1px;
    border-radius: 0;
}

.question-img {
    max-width: 75px;
}

@media (min-width: 374px){
    .question-img {
        max-width: 100px;
    }
}

@media (min-width: 768px){
    .question-img {
        max-width: 125px;
    }
}
